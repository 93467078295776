
import React from 'react';
import {useState} from 'react';
import "./NoAccess.scss";

var base64Decode = function(str: string){
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
};

var getProjectID = function(jwt: string){
  var payload = jwt?jwt.split('.')[1]:null;
  if(!payload){
    console.log(jwt);
    throw new Error('Malformed JWT');
  }
  var payloadObj = JSON.parse(base64Decode(payload));
  return payloadObj?payloadObj.tenantID:null;
};

var setSession = function(projectID: string, jwt: string){
  var dbReq = indexedDB.open('experience-studio');
  dbReq.onsuccess = function(evt: any){
    if(!evt){
      throw new Error('Invalid storage');
      return;
    }
    var putError = function(e: any){
      console.error(e);
      throw new Error('Failed to store values');
    };

    var db = evt?.target?.result;
    var objStore = db.transaction('templated_experience','readwrite').objectStore('templated_experience');
    var req1 = objStore.put(jwt, projectID+'+token');
    req1.onerror = putError;
    var req2 = objStore.put('https://mycard2.demo.flybits.com', projectID+'+origin');
    req2.onerror = putError;
  }
};

var redirectTenant = function(projectID: string){
  window.location.replace('/project/'+projectID);
};

export default function NoAccess() {
  var [jwt, setJwt] = useState('');

  var handleSubmit = function(){
    if(!jwt){
      return;
    }
    try{
      var projectID = getProjectID(jwt);
      setSession(projectID, jwt);
      setTimeout(function(){
        redirectTenant(projectID);
      }, 200);
    } catch(e){
      alert('Invalid JWT: '+ JSON.stringify(e))
      console.log(e);
    }
  }
    
  return (
      <div className="no-access">
        <div className="header">
          Access Denied
        </div>
        <div className="jwtinject">
          <input type="text" className="jwtinject" placeholder="Enter Flybits JWT" value={jwt} onChange={(e)=>setJwt(e.target.value)}/>
          <div className="btn submit" onClick={handleSubmit}>Submit</div>
        </div>
    </div>
  )
}
