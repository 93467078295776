import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import SettingsAPI from 'services/api/setting.api';
import ControlBar from 'components/ControlBar/ControlBar';
import './LibrarySelection.scss';
import UseAsyncState from 'hooks/useAsyncState';
import queryString from 'query-string';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import { FilterBar } from 'components/PointSolutions/FilterBar/FilterBar';
import { LibraryCard } from 'components/PointSolutions/LibraryCard/LibraryCard';
import { TemplateCard } from 'components/PointSolutions/TemplateCard/TemplateCard';
import { SubcategoryColumn } from 'components/PointSolutions/SubcategoryColumn/SubcategoryColumn';
import { FlightCarousel, FlightCarouselItem, FlightSnackbar } from '@flybits/webapp-design-system-react';
import { uniqBy, uniq, isEmpty, delay, times } from 'lodash';
import { getAfterDelimiter } from 'helpers/string.helper';
import { TemplateSidePanel } from 'components/PointSolutions/TemplateSidePanel/TemplateSidePanel';
import Skeleton from 'react-loading-skeleton';
import useFeatureFlag from 'hooks/useFeatureFlag';

interface Carousel {
  name?: string;
  img?: string;
  description?: string;
  key: string;
}

enum ReservedTypes {
  'byo',
  'all',
}

interface Obj {
  key: string;
  name: string;
  iconURL: string;
  isActivated: boolean;
  bgColor: string;
}

interface SubCategory {
  key: string;
  name: string;
  iconURL: string;
  isActivated: boolean;
  bgColor: string;
  subcategories: Obj[];
}

interface TemplateSelection {
  audienceOptimizationEligible: boolean;
  automationBudget: { populationSize: number };
  createdAt: number;
  desc: string;
  explorationBudget: { populationSize: number };
  icon: string;
  id: string;
  journeyMode: string;
  name: string;
  rootStepID: string;
  steps: any[];
  tenantID: string;
  updatedAt: number;
  isMulti?: boolean;
  category?: any;
  subCategory?: Obj;
  filters?: [];
  tags: [];
}

export default function LibrarySelection(props: any) {
  const history = useHistory();
  const journeyApi = new TemplatedExperienceAPI();
  const settingsApi = new SettingsAPI();
  const [showPanel, setShowPanel] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const { flags } = useFeatureFlag();

  const [arrData, isLoading, isError] = UseAsyncState(
    () =>
      journeyApi.getTemplatedExperiences({ search: searchTerm }).then((r) => {
        if (!flags['tx_nearby_template']) {
          return r.data.filter((template: any) => template.id !== '78AC7190-0863-40EE-82C7-133C46655576');
        }
        return r.data;
      }),
    [searchTerm],
  );

  const [libData, isLibDataLoading] = UseAsyncState(() => settingsApi.getCategories().then((r) => r.settings), [
    searchTerm,
  ]);

  const queryParams = queryString.parse(props.location?.search);

  const handleCancel = () => {
    history.push(`/project/${history?.location?.pathname?.split('/')[2]}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
  };

  const [carouselState, setCarouselState] = useState<Carousel>({
    // will be set from API by the useEffect hook below
    name: '',
    description: '',
    key: 'all',
  });

  useEffect(() => {
    setCarouselState({
      name: libData?.categories?.find((item: { key: string }) => item?.key === queryParams.library ? String(queryParams.library) : 'all')?.name,
      description: libData?.categories?.find((item: { key: string }) => item?.key === queryParams.library ? String(queryParams.library) : 'all')?.description,
      key: queryParams.library ? String(queryParams.library) : 'all',
    });
  }, [libData, queryParams.library]);

  const [mergedData, setMergedData] = useState<TemplateSelection[]>([]);
  const [templatesToRender, setTemplatesToRender] = useState<TemplateSelection[]>([]);
  const isSearchTermEmpty = () => {
    return searchTerm === '';
  };

  const mergeTemplateData = (data: TemplateSelection[]) => {
    if (!isSearchTermEmpty()) {
      if (data.length) {
        data.map((item: any) => {
          const _item = {
            ...item,
            ...{
              filters: item?.tags?.filter(
                (kpi: string) => !kpi?.includes('subcategory-') && !kpi?.includes('category-'),
              ),
            },
          };
          return setMergedData([_item]);
        });
      } else {
        return setMergedData(data);
      }
    }

    data?.map((item: TemplateSelection) => {
      const _getTemplateCatName = item?.tags?.find((el: string) => el?.includes('category'));
      const templateCatName = _getTemplateCatName && getAfterDelimiter(_getTemplateCatName, '-');
      const _getTemplateSubCatName = item?.tags?.find((el: string) => el?.includes('subcategory'));
      const templateSubCatName = _getTemplateSubCatName && getAfterDelimiter(_getTemplateSubCatName, '-');
      if (templateCatName) {
        //here, we look see if categories exist on the template
        //look for the exact category and subcat and append to template
        libData?.categories?.map((cat: any) => {
          if (cat.key === templateCatName && cat.subcategories.map((_cat: Obj) => _cat.key === templateSubCatName)) {
            return setMergedData((prevState: any) =>
              uniqBy(
                [
                  ...prevState,
                  {
                    ...item,
                    ...{
                      category: { name: cat?.name, key: cat?.key, img: cat?.img, color: cat?.color },
                      subcategory: cat?.subcategories?.find(
                        (_subCatItem: Obj) => _subCatItem.key === templateSubCatName,
                      ),
                      filters: item?.tags?.filter(
                        (kpi: string) => !kpi?.includes('subcategory-') && !kpi?.includes('category-'),
                      ),
                    },
                  },
                ],
                'id',
              ),
            );
          }
          return null;
        });
      }
      if (item?.tags?.length === 0 || !item?.tags) {
        return setMergedData((prevState: TemplateSelection[]) =>
          uniqBy([...prevState, { ...item, ...{ category: [] } }], 'id'),
        );
      }
    });
  };

  const togglePanel = () => {
    if(showPanel) {
      delete queryParams.template_id;
      history.push(`${history?.location?.pathname}${!isEmpty(queryParams) ? '?'+queryString.stringify(queryParams) : ''}`);
    }
    setShowPanel(!showPanel);
  };

  const openTemplatePanel = (template: TemplateSelection) => {
    setCurrentTemplate(template);
    setShowPanel(true);
  };

  useEffect(() => {
    // this hook is used to 'preselect' or remember the template clicked
    // we select this template and set the appropriate library and open the template
    if (mergedData && queryParams.template_id) {
      const _preSelectedTemplate = mergedData.find((item: { id: string }) => item.id === queryParams.template_id);
      setCurrentTemplate(_preSelectedTemplate);
      delay(() => setShowPanel(true), 1000);
    }
    if(queryParams.library) {
      const _preSelectedLibraryObject = libData?.categories?.find(
        (item: { key: string }) => item?.key === queryParams.library,
      );
      setCarouselState({
        name: _preSelectedLibraryObject?.name,
        description: _preSelectedLibraryObject?.description,
        key: _preSelectedLibraryObject?.key || 'all',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.library, queryParams.template_id, mergedData]);


  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    if (value !== '') {
      setCarouselState({
        name: 'All',
        description: 'All the usecases you need to achieve your goals.',
        key: 'all',
      });
    }
  };

  const [subCategories, setSubcategories] = useState<SubCategory[]>([]);
  useEffect(() => {
    const _getSubCats = libData?.categories?.find((categoryItem: any) => categoryItem.key === carouselState?.key);
    setSubcategories(_getSubCats?.subcategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselState?.key]);

  const [filters, setFilters] = useState<string[]>([]);
  useEffect(() => {
    if (carouselState && carouselState?.key in ReservedTypes) {
      const _allFilters = mergedData
        ?.flatMap((item: TemplateSelection) => item?.filters || '')
        ?.filter((removeEmpty: string) => removeEmpty !== '');
      return setFilters(uniq(_allFilters));
    } else {
      const _getFiltersForSubCats = mergedData?.filter(
        (templateData: TemplateSelection) => templateData?.category?.key === carouselState?.key,
      );
      const _subCatFilters = _getFiltersForSubCats?.flatMap((item: TemplateSelection) => item?.filters || '');
      return setFilters(uniq(_subCatFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedData, carouselState?.key]);

  useEffect(() => {
    mergeTemplateData(arrData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrData, libData]);

  useEffect(() => {
    setTemplatesToRender(mergedData);
  }, [mergedData]);

  const [filterDelay, setFilterDelay] = useState(!libData ? true : false);
  const filterBySelection = (args: any) => {
    delay(
      function () {
        setFilterDelay(true);
      },
      1000,
      'later',
    );
    if (!isEmpty(args)) {
      const stringIncludesEvery = (value: any) => args?.every((element: any) => value?.filters?.includes(element));
      setFilterDelay(false);
      return setTemplatesToRender(mergedData?.filter(stringIncludesEvery));
    }
    setFilterDelay(false);
    return setTemplatesToRender(mergedData);
  };

  const RenderErrorBanner = () => {
    const [closeSnackbar, setCloseSnackBar] = useState(false);
    const message = `Error fetching ${isError && `templates`} `;
    useEffect(() => {
      if (isError) setCloseSnackBar(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    return (
      <FlightSnackbar
        isVisible={closeSnackbar}
        isFloating={false}
        type="error"
        className="library-selection__error-banner"
        content={message}
        isAutoDismiss={true}
        action={() => {
          setCloseSnackBar(false);
        }}
        actionName="Close"
      />
    );
  };

  const handleTemplateCardClick = (template: TemplateSelection) => {
    openTemplatePanel(template);
  };

  return (
    <ControlBar
      onCancel={handleCancel}
      showSearchBar
      handleSearchInput={handleSearch}
      searchPlaceHolderText={libData ? 'Search all libraries' : 'Search templates'}
    >
      <RenderErrorBanner />
      <div className="library-selection">
        <div className={'library-selection__container' + (showPanel ? ' panel-open' : '')}>
          <div className="library-selection__container__block">
            <div className="mc-banner">
              <img className="mc-logo" src="https://flybits.app/resources/mc.png"/>
              <div className="mc-title">Experience Marketplace</div>
            </div>
            {isSearchTermEmpty() && (
              <div>
                {libData && (
                  <>
                    {
                      <div className="library-selection__container__block__wrapper">
                        <FlightCarousel className="library-selection__container__block__carousel">
                          {libData?.categories?.map((item: Carousel) => {
                            return (
                              <FlightCarouselItem
                                key={item.key}
                                className="library-selection__container__block__carousel__item"
                                onClick={() => {
                                  queryParams.library = item.key;
                                  delete queryParams.template_id;
                                  history.replace(`/project/${history?.location?.pathname?.split('/')[2]}/library-selection${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`)
                                  setCarouselState(item);
                                  setShowPanel(false);
                                }}
                              >
                                {!isLibDataLoading ? (
                                  <LibraryCard
                                    name={item.name || ''}
                                    backgroundImage={item.img}
                                    isSelected={item?.key === carouselState?.key}
                                  />
                                ) : (
                                  <LoadingIcon className="library-selection__container__block__all__loading" />
                                )}
                              </FlightCarouselItem>
                            );
                          })}
                        </FlightCarousel>
                      </div>
                    }
                  </>
                )}
              </div>
            )}
            {!isEmpty(filters) && carouselState?.key !== 'byo' && (
              <FilterBar
                className="library-selection__container__block__filter-bar"
                filters={filters}
                handleFilter={(e) => filterBySelection(e)}
              />
            )}
            {carouselState?.key === 'all' && (
              <div className="library-selection__container__block__all">
                {isLoading ? (
                  <LoadingIcon className="library-selection__container__block__all__loading" />
                ) : !isEmpty(templatesToRender) ? (
                  templatesToRender?.map((template: TemplateSelection) => {
                    return filterDelay ? (
                      <TemplateCard
                        key={template?.id}
                        id={template?.id}
                        location={props?.location?.search}
                        selectedTemplateCard={currentTemplate?.id === template?.id}
                        library={template?.category?.name}
                        headerImage={template?.icon}
                        name={template?.name}
                        libColor={template?.category?.color}
                        className="library-selection__container__block__all__template-card"
                        onClick={() => handleTemplateCardClick(template)}
                      />
                    ) : (
                      times(20, (i) => <Skeleton key={i} width="100%" height={200} />)
                    );
                  })
                ) : (
                  'No results found.'
                )}
              </div>
            )}
            {carouselState?.key === 'byo' && (
              <div className="library-selection__container__block__all">
                {!isEmpty(templatesToRender)
                  ? templatesToRender
                      ?.filter((item: TemplateSelection) => isEmpty(item.category))
                      ?.map((template: TemplateSelection) => {
                        return (
                          <TemplateCard
                            key={template?.id}
                            id={template?.id}
                            location={props?.location?.search}
                            selectedTemplateCard={currentTemplate?.id === template?.id}
                            library={template?.category?.name || ''}
                            name={template?.name}
                            headerImage={template?.icon}
                            libColor={template?.category?.color}
                            className="library-selection__container__block__all__template-card"
                            onClick={() => handleTemplateCardClick(template)}
                          />
                        );
                      })
                  : 'No results found.'}
              </div>
            )}
            {carouselState?.key in ReservedTypes ? null : (
              <div className="library-selection__container__block__subcategories">
                {subCategories?.map((item: Obj, index: number) => {
                  return (
                    <SubcategoryColumn
                      className="library-selection__container__block__subcategory"
                      subcategory={item?.name}
                      subcatKey={item?.key}
                      iconURL={item?.iconURL}
                      isActivated={item?.isActivated}
                      bgColor={item?.bgColor}
                      index={index + 1}
                      isLastColumn={index === subCategories?.length - 1}
                      templates={templatesToRender?.filter(
                        (templateData: TemplateSelection) => templateData?.category?.key === carouselState?.key,
                      )}
                      onClick={(template: TemplateSelection) => openTemplatePanel(template)}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <TemplateSidePanel
          template={currentTemplate}
          showPanel={showPanel}
          togglePanel={togglePanel}
          location={props?.location?.search}
        />
      </div>
    </ControlBar>
  );
}
